var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{staticClass:"tabela-repeticao",attrs:{"items":_vm.itensInner,"fields":_vm.colunasInner,"responsive":_vm.responsive,"no-local-sorting":_vm.sortingLocal,"hover":""},on:{"sort-changed":_vm.checarSorting},scopedSlots:_vm._u([{key:"head()",fn:function(data){return [(_vm.temSelecao && data.field.key === 'select')?_c('div',[_c('b-form-checkbox',{staticClass:"p-0",staticStyle:{"margin-left":"2.5rem !important","min-height":"0 !important"},attrs:{"value":true,"indeterminate":_vm.indeterminateCheckbox,"size":"sm"},on:{"change":_vm.toogleSelectAll},model:{value:(_vm.selectAllCheckbox),callback:function ($$v) {_vm.selectAllCheckbox=$$v},expression:"selectAllCheckbox"}})],1):_vm._e()]}},{key:"cell()",fn:function(ref){
var valor = ref.value;
var item = ref.item;
var ref_field = ref.field;
var key = ref_field.key;
var tipo = ref_field.tipo;
var classe = ref_field.classe;
return [(item.emEdicao)?_vm._t("edicao",null,{"slotScope":{item: item, key: key, tipo: tipo, classe: classe}}):(_vm.temSelecao && key === 'select')?_c('div',[_c('b-form-checkbox',{staticClass:"p-0",staticStyle:{"margin-left":"2.5rem !important","min-height":"0 !important"},attrs:{"value":item.id,"name":"select","size":"sm"},model:{value:(_vm.itensSelecionados),callback:function ($$v) {_vm.itensSelecionados=$$v},expression:"itensSelecionados"}})],1):_vm._t("visualizacao",null,{"slotScope":{valor: valor, item: item, key: key, tipo: tipo, }})]}},{key:"cell(acoes)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.customizarBotoes)?_vm._t("botoes-customizados",null,{"slotScope":{item: item, index: index}}):_c('acoes-linha',{attrs:{"item":item,"index":index,"validar":_vm.validar,"edicao":item.emEdicao,"ultimo":(_vm.rows - 1) === index},on:{"input":_vm.salvarLinha,"novo":_vm.novaLinha,"cancelar":_vm.cancelarLinha,"editar":_vm.editarLinha,"remover":_vm.removerLinha}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }