<template>
  <b-table
    :items="itensInner"
    :fields="colunasInner"
    :responsive="responsive"
    :no-local-sorting="sortingLocal"
    class="tabela-repeticao"
    hover
    @sort-changed="checarSorting"
  >
    <template v-slot:head()="data">
      <div v-if="temSelecao && data.field.key === 'select'">
        <b-form-checkbox
          v-model="selectAllCheckbox"
          :value="true"
          :indeterminate="indeterminateCheckbox"
          size="sm"
          class="p-0"
          style="margin-left: 2.5rem !important; min-height: 0 !important;"
          @change="toogleSelectAll"
        />
      </div>
    </template>

    <template v-slot:cell()="{ value: valor, item, field: { key, tipo, classe }}">

      <slot
        v-if="item.emEdicao"
        :slot-scope="{item, key, tipo, classe}"
        name="edicao"
      />
      <div v-else-if="temSelecao && key === 'select'">
        <b-form-checkbox
          v-model="itensSelecionados"
          :value="item.id"
          name="select"
          size="sm"
          class="p-0"
          style="margin-left: 2.5rem !important; min-height: 0 !important;"
        />
      </div>
      <slot
        v-else
        :slot-scope="{valor, item, key, tipo, }"
        :class="classe"
        name="visualizacao"
      />
    </template>

    <template #cell(acoes)="{ item, index }">
      <slot
        v-if="customizarBotoes"
        :slot-scope="{item, index}"
        name="botoes-customizados"
      />
      <acoes-linha
        v-else
        :item="item"
        :index="index"
        :validar="validar"
        :edicao="item.emEdicao"
        :ultimo="(rows - 1) === index"
        @input="salvarLinha"
        @novo="novaLinha"
        @cancelar="cancelarLinha"
        @editar="editarLinha"
        @remover="removerLinha"
      />
    </template>
  </b-table>
</template>

<style lang="scss">
.tabela-repeticao {
  .form-control {
    padding: 0.438rem 0.7rem;
    height: 2.5rem;
  }

  th {
    background-color: #f8f8f8 !important;
    font-size: 0.757rem!important;
    padding: 0.5rem 0.2rem;
  }

  td {
    padding: 0.5rem 0.2rem;
    font-size: 0.857rem!important;
  }

  .table-hover {
    tr {
      cursor: default !important;
    }
  }
}
</style>

<script>

export default {
  name: 'TabelaRepeticao',
  components: {
    AcoesLinha: () => import('./shared/components/AcoesLinha.vue'),
  },
  props: {
    colunas: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    validar: {
      type: Function,
      default: null,
    },
    somenteVisualizacao: {
      type: Boolean,
      default: () => true,
    },
    customizarBotoes: {
      type: Boolean,
      default: () => false,
    },
    temSelecao: {
      type: Boolean,
      default: () => false,
    },
    responsive: {
      type: Boolean,
      default: () => false,
    },
    sortingLocal: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      colunasInner: [],
      itensInner: [],
      itensSelecionados: [],
      selectAllCheckbox: false,
      indeterminateCheckbox: false,
      ordenacao: [],
    }
  },
  computed: {
    rows() {
      return this.itensInner.length
    },
  },
  watch: {
    value(value, oldValue) {
      if (value && value !== oldValue) {
        this.itensInner = value
        if (this.itensInner.length === 0) {
          this.novaLinha({ code: null, emEdicao: true })
        }
      }
    },
    colunas(value, oldValue) {
      if (value && value !== oldValue) {
        this.colunasInner = value
      }
    },
    itensSelecionados(value) {
      if (value.length === 0) {
        this.indeterminateCheckbox = false
        this.selectAllCheckbox = false
      } else if (this.itensSelecionados.length === this.itensInner.length) {
        this.indeterminateCheckbox = false
        this.selectAllCheckbox = true
      } else {
        this.indeterminateCheckbox = true
        this.selectAllCheckbox = false
      }
      this.$emit('selected-items', value)
    },
  },
  created() {
    if (this.somenteVisualizacao) {
      this.colunasInner = [...this.colunas]
    } else {
      this.colunasInner = [...this.colunas, {
        key: 'acoes', label: 'Ações', width: '50px', class: 'col-nowrap',
      }]
    }
    this.itensInner = this.value
    if (!this.itensInner || this.itensInner.length === 0) {
      this.novaLinha({ code: null, emEdicao: true })
    }
  },
  methods: {
    linhaEmEdicao(item) {
      return item.edicao
    },
    salvarLinha(item, index) {
      this.$set(this.itensInner, index, item)
      this.$set(this.itensInner[index], 'emEdicao', item.emEdicao)
    },
    novaLinha(item) {
      this.itensInner.push(item)
    },
    cancelarLinha(item, index) {
      this.$set(this.itensInner, index, item)

      if (this.itensInner.length === 0) {
        this.novaLinha({ code: null, emEdicao: true })
      }
    },
    removerLinha(index) {
      if (index >= 0) {
        this.itensInner.splice(index, 1)
      }

      if (this.itensInner.length === 0) {
        this.novaLinha({ code: null, emEdicao: true })
      }
    },
    editarLinha(item, index) {
      for (let i = 0; i < this.itensInner.length; i += 1) {
        if (i === index) {
          this.$set(this.itensInner, i, item)
        }
        this.$set(this.itensInner[i], 'emEdicao', i === index)
      }
    },
    toogleSelectAll() {
      this.itensSelecionados = this.selectAllCheckbox ? this.itensInner.map(map => map.id) : []
    },
    checarSorting(param) {
      const colOrdenada = param
      this.ordenacao = [{
        coluna: colOrdenada.sortBy,
        tipo: colOrdenada.sortDesc ? 'desc' : 'asc',
      }]
      this.$emit('changed-sort', this.ordenacao)
    },
  },
}
</script>
